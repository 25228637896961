import React, { useEffect, useState } from "react";
import tw, { styled, css } from "twin.macro";
import SectionCountry from "./SectionCountry";
import SectionDisclaimer from "./SectionDisclaimer";
import SectionContactUs from "./SectionContactUs";
import SectionServices from "./SectionServices";
import SectionServices2 from "./SectionServices2";

const FooterWrapper = tw.footer`bg-black px-4 xl:px-12  text-white`;

const FooterContainer = styled.div(() => [
  tw`pt-6`,
  tw`md:pt-7`,
  // css`
  //   @media screen and (min-width: 1366px) {
  //     padding-left: 100px;
  //     padding-right: 100px;
  //   }
  // `,
]);

const date = new Date();
let year = date.getFullYear();

const SectionCopyright: React.FC = () => (
  <section tw="pt-6 pb-16 text-xs md:text-sm md:flex md:justify-between md:items-start border-t border-gray-1">
    <p tw="leading-5">
      © 2011-{year}, KnowledgeHut Solutions Private Limited. All Rights Reserved
    </p>
    <div>
      <ul tw="md:flex">
        <li tw="pt-4 md:pr-10 md:pt-0">
          <a href="/privacy-policy">Privacy policy</a>
        </li>
        <li tw="pt-4 md:pt-0">
          <a href="/terms-conditions">Terms of service</a>
        </li>
      </ul>
    </div>
  </section>
);

const Footer: React.FC = () => {
  return (
    <>
      <FooterWrapper>
        <FooterContainer>
          <SectionContactUs />
          <SectionCountry />
          <SectionServices />
          <SectionServices2 />
          <SectionDisclaimer />
          <SectionCopyright />
        </FooterContainer>
      </FooterWrapper>
    </>
  );
};

export default Footer;
