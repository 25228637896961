import Link from "next/link";
import React, { useState } from "react";
import tw from "twin.macro";
import {
  companyService,
  partnerService,
  supportService,
  resourceService,
  offeringService,
} from "./data";

type ServiceItem = {
  name: string;
  href: string;
  rel?: number;
};

const ServiceWrapper = tw.section`border-t md:border-b border-gray-1 py-2 md:py-10`;

const DesktopServiceItem: React.FC<{ name: string; service: ServiceItem[] }> =
  ({ name, service }) => {
    return (
      <div>
        <p tw="text-[21px] font-semibold h-11 2xl:h-auto ">{name}</p>
        <ul tw="pt-6">
          {service.map(({ name, href, rel }, idx) => (
            <li key={idx} tw="text-sm h-10 leading-7 2xl:h-auto">
              <Link href={href} passHref>
                {rel == 1 ? (
                  <a title={name} rel="nofollow">
                    {name}
                  </a>
                ) : (
                  <a title={name}>{name}</a>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

const MobileServiceItem: React.FC<{
  isActive: boolean;
  name: string;
  service: ServiceItem[];
  onClick: (name: string) => void;
}> = ({ isActive, name, service, onClick }) => {
  return (
    <div tw="text-gray-4 border-b border-gray-1 ">
      <div
        tw="relative flex items-center w-full text-left py-4 text-sm font-semibold"
        onClick={() => onClick(name)}
      >
        {name}
        <span tw="absolute right-0 transform -translate-y-1/2">
          <i
            className={`icon-down-arrow-white blog-icons inline-block transition-transform duration-300 ${isActive && "rotate-180"}`}
          ></i>
        </span>
      </div>
      <div
        tw="transition-all duration-300 overflow-hidden max-h-0"
        css={[isActive && tw`max-h-96`]}
      >
        <ul tw="px-3 pb-4">
          {service.map(({ name, href, rel }, idx) => (
            <li key={idx} tw="text-xs leading-8">
              <Link href={href} passHref>
                {rel == 1 ? (
                  <a title={name} rel="nofollow">
                    {name}
                  </a>
                ) : (
                  <a title={name}>{name}</a>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SectionServices: React.FC = () => {
  const [serviceActive, setService] = useState<string>("");

  const onServiceClick = (name: string) => {
    setService((service) => {
      if (service === name) {
        return "";
      }

      return name;
    });
  };
  return (
    <ServiceWrapper>
      <div tw="md:hidden md:invisible">
        <MobileServiceItem
          name="Company"
          service={companyService}
          isActive={serviceActive === "Company"}
          onClick={onServiceClick}
        />
        <MobileServiceItem
          name="Offerings"
          service={offeringService}
          isActive={serviceActive === "Offerings"}
          onClick={onServiceClick}
        />
        <MobileServiceItem
          name="Resources"
          service={resourceService}
          isActive={serviceActive === "Resources"}
          onClick={onServiceClick}
        />
        <MobileServiceItem
          name="Partner with us"
          service={partnerService}
          isActive={serviceActive === "Partner with us"}
          onClick={onServiceClick}
        />
        <MobileServiceItem
          name="Support"
          service={supportService}
          isActive={serviceActive === "Support"}
          onClick={onServiceClick}
        />

        
      </div>
      <div tw="md:grid md:grid-cols-5 md:visible invisible hidden ">
        <DesktopServiceItem name="Company" service={companyService} />
        <DesktopServiceItem name="Offerings" service={offeringService} />
        <DesktopServiceItem name="Resources" service={resourceService} />
        <DesktopServiceItem name="Partner with us" service={partnerService} />
        <DesktopServiceItem name="Support" service={supportService} />
      </div>
    </ServiceWrapper>
  );
};

export default SectionServices;
