import React from "react";
import tw from "twin.macro";

type CountryContactProps = {
  flag: string;
  name: string;
  countryPhoneNumber?: string[] | string;
  tollFreeNumber?: string;
  noSpacing?: boolean;
};

export const CountryItem: React.FC<CountryContactProps> = ({
  name,
  flag,
  countryPhoneNumber,
  tollFreeNumber,
  noSpacing = false,
}) => {
  const getCountryNumber = (phoneNumber: string | string[]) => {
    if (typeof phoneNumber !== "string") {
      return phoneNumber.map((item, i) => [
        i > 0 && ", ",
        <a key={item + i} href={`tel:${item}`} rel="nofollow">
          {item}
        </a>,
      ]);
    }
    return <a href={`tel:${phoneNumber}`} rel="nofollow">{phoneNumber}</a>;
  };
  return (
    <div css={[tw`pb-[18px] md:pb-6 relative`, noSpacing && tw`2xl:pb-0`]}>
      <div tw="text-xs leading-[17px] md:leading-[18px] flex gap-5">
        <i className={`${flag} blog-icons inline-block`}></i>
        <div>
        <div tw="text-[13px]">
          {`${name}: `}
          {countryPhoneNumber ? (
            getCountryNumber(countryPhoneNumber)
          ) : (
            <span>
              Toll Free {<a href={`tel:${tollFreeNumber}`} rel="nofollow" >{tollFreeNumber}</a>}
            </span>
          )}
        </div>
        {tollFreeNumber && countryPhoneNumber && (
          <div tw="text-[13px]">
            Toll Free: {<a href={`tel:${tollFreeNumber}`} rel="nofollow">{tollFreeNumber}</a>}
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

const SectionCountry: React.FC = () => {
  return (
    <section tw="border-t border-gray-1 pt-8 pb-2 md:pb-8 md:grid md:grid-cols-2 md:justify-start 2xl:flex 2xl:grid-cols-none 2xl:pr-[15px] 2xl:flex-row">
      <div tw="2xl:mr-[25px] 2xl:flex-1 2xl:flex-basis[auto] ">
        <CountryItem
          name="USA"
          countryPhoneNumber={["+1-469-442-0620", "+1-832-684-0080"]}
          flag="icon-usa"
        />
        <CountryItem
          name="India"
          // countryPhoneNumber="+91-84484-45027"
          flag="icon-india"
          tollFreeNumber="1800-121-9232"
          noSpacing
        />
      </div>
      <div tw="2xl:px-[15px] 2xl:mr-[25px] 2xl:border-l border-gray-1 2xl:flex-1 2xl:flex-basis[auto]">
        <CountryItem name="UK" countryPhoneNumber="+44-2036085923" flag="icon-uk" />
        <CountryItem
          name="Singapore"
          countryPhoneNumber="+65-317-46174"
          flag="icon-singapore"
        />
        <CountryItem
          name="Malaysia"
          countryPhoneNumber="+601548770914"
          flag="icon-malaysia"
          noSpacing
        />
      </div>
      <div tw="md:-mt-6 2xl:mt-0 2xl:px-[15px] 2xl:mr-[25px] 2xl:border-l border-gray-1 2xl:flex-1 2xl:flex-basis[auto]">
        <CountryItem
          name="Canada"
          countryPhoneNumber="+1-613-707-0763"
          flag="icon-canada"
        />
        <CountryItem
          name="New Zealand"
          countryPhoneNumber="+64-36694791"
          flag="icon-new-zealand"
        />
        <CountryItem
          name="Ireland"
          countryPhoneNumber="+353-12708328"
          flag="icon-ireland"
          noSpacing
        />
      </div>
      <div tw="2xl:px-[15px] 2xl:border-l border-gray-1 2xl:flex-1 2xl:flex-basis[auto]">
        <CountryItem
          name="Australia"
          countryPhoneNumber="+61-290995641"
          flag="icon-australia"
        />
        <CountryItem
          name="UAE"
          flag="icon-uae"
          tollFreeNumber="8000180860"
          noSpacing
        />
      </div>
    </section>
  );
};

export default SectionCountry;
