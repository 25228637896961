import Link from "next/link";
import React, { useState } from "react";
import tw from "twin.macro";
import { topCategories, topCourses } from "./data";

type ServiceItem = {
  name: string;
  href: string;
  rel?: number;
};

const ServiceWrapper = tw.section`md:border-b md:border-gray-1 py-0 md:py-8`;

const DesktopServiceItem: React.FC<{
  name: string;
  service: ServiceItem[];
  count:number;
}> = ({ name, service,count}) => {
  return (
    <div className="">
      <p tw="text-[16px] text-[#cccccc] font-semibold h-11 2xl:h-auto rounded-[16px] ">
        {name}
      </p>
      <ul tw="pt-[23px] grid grid-cols-2">
        <div>
          {service.slice(0, count).map(({ name, href, rel }, idx) => (
            <li
              key={idx}
              tw="text-[13px] text-[#cccccc] h-10 leading-7 2xl:h-auto"
            >
              <Link href={href} passHref>
                {rel == 1 ? (
                  <a title={name} rel="nofollow" target="_blank">
                    {name}
                  </a>
                ) : (
                  <a target="_blank"  title={name}>{name} </a>
                )}
              </Link>
            </li>
          ))}
        </div>
        <div>
          {service.slice(count).map(({ name, href, rel }, idx) => (
            <li
              key={idx}
              tw="text-[13px] text-[#cccccc] h-10 leading-7 2xl:h-auto"
            >
              <Link href={href} passHref>
                {rel == 1 ? (
                  <a title={name} rel="nofollow" target="_blank">
                    {name}
                  </a>
                ) : (
                  <a target="_blank" title={name}>{name}</a>
                )}
              </Link>
            </li>
          ))}
        </div>
      </ul>
    </div>
  );
};

const MobileServiceItem: React.FC<{
  isActive: boolean;
  name: string;
  service: ServiceItem[];
  onClick: (name: string) => void;
}> = ({ isActive, name, service, onClick }) => {
  return (
    <div tw="text-gray-4 border-b border-gray-1 ">
      <div
        tw="relative block w-full text-left py-4 text-sm font-semibold"
        onClick={() => onClick(name)}
      >
        {name}
        <span tw="absolute  right-0 transform -translate-y-1/2">
          <i
            className={`icon-down-arrow-white blog-icons inline-block transition-transform duration-300 ${
              isActive && "rotate-180"
            }`}
          ></i>
        </span>
      </div>
      <div
        tw="transition-all duration-300 overflow-hidden max-h-0"
        css={[isActive && tw`max-h-[500px]`]}
      >
        <ul tw="px-3 pb-4">
          {service.map(({ name, href, rel }, idx) => (
            <li key={idx} tw="text-xs leading-8">
              <Link href={href} passHref>
                {rel == 1 ? (
                  <a title={name} rel="nofollow" target="_blank">
                    {name}
                  </a>
                ) : (
                  <a  target="_blank" title={name}>{name}</a>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SectionServices2: React.FC = () => {
  const [serviceActive, setService] = useState<string>("");

  const onServiceClick = (name: string) => {
    setService((service) => {
      if (service === name) {
        return "";
      }

      return name;
    });
  };
  return (
    <ServiceWrapper>
      <div tw="md:hidden md:invisible">
        <MobileServiceItem
          name="Top Categories"
          service={topCategories}
          isActive={serviceActive === "Top Categories"}
          onClick={onServiceClick}
        />
        <MobileServiceItem
          name="Top Courses"
          service={topCourses}
          isActive={serviceActive === "Top Courses"}
          onClick={onServiceClick}
        />
      </div>
      <div tw="md:grid md:grid-cols-2 md:visible invisible hidden ">
        <DesktopServiceItem name="Top Categories" service={topCategories} count={7} />
        <DesktopServiceItem name="Top Courses" service={topCourses}  count={6}/>
      </div>
    </ServiceWrapper>
  );
};

export default SectionServices2;
